import React, { useState } from "react";
import { BrowserRouter as Routes, Route, Switch } from "react-router-dom";
import Categories from "./Categories"
import Footer from "./Footer";
import  Header  from "./Header"; 
import MusicControls from "./MusicControls";
import Nav from "./Nav";


const Main = () => {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="outerWrap">
      <div className="App">
        <Nav />
        <div className="main">
          <Header />

          <div className="mainContent">
            <Categories />
            <Footer />
          </div>
        </div>
      </div>

      <div className="musicContols">
        <MusicControls />
      </div>
    </div>
  );
};

export default Main;
