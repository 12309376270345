import react, { useState } from "react";
import SearchCat from "../SearchCat";
import Footer from "../Footer";
import Header from "../Header";
import MusicControls from "../MusicControls";
import Nav from "../Nav";

const Search = () => {
  return (
    <div className="outerWrap">
      <div className="App">
        <Nav />
        <div className="main">
          <Header />

          <div className="mainContent">
            <SearchCat />
            <Footer />
          </div>
        </div>
      </div>

      <div className="musicContols">
        <MusicControls />
      </div>
    </div>
  );
};

export default Search;
