import react from 'react';
//import { Button } from "react-native-web";
import './App.scss';
import Nav from "./components/Nav";
import Main from "./components/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MusicControls from "./components/MusicControls";
import SignUp from "./components/pages/SignUp";
import Search from "./components/pages/Search";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        {/* <Route path="/sign-up" element={<SignUp />} />*/}
        <Route path="/search" element={<Search />} />
      </Routes>
    </Router>
  );
}

export default App;

