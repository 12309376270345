import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link} from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp";

const Header = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="upperNav">
      <div className="upperNavLeft">
        <span>
          <FaChevronLeft />
        </span>
        <span>
          <FaChevronRight />
        </span>
      </div>

      <div className="upperNavRight">
        {/* */}
        <div className="support">
          <li>Premium</li>
          <li>Support</li>
          <li>Download</li>
        </div>

        <div className="accounts">
          <div className="btn btn-signUp">
            <Link to="/sign-up">
              <li>Sign up</li>
            </Link>
          </div>
          <div>
            <button className="btn btn-login">Log in</button>
          </div>
        </div>

        {/* {isSmallScreen ? (
          <div className="hamburger-menu">
            <div className="icon" onClick={toggleDropdown}>
              &#8801; Unicode for the hamburger icon 
            </div>
            {isDropdownVisible && (
              <div className="dropdown">
                <li>Premium</li>
                <li>Support</li>
                <li>Download</li>
              </div>
            )}
          </div>
        ) : (
          <div className="support">
            <li>Premium</li>
            <li>Support</li>
            <li>Download</li>
          </div>
        )}

         <div className="accounts">
          <div className="btn btn-signUp">
            <li>Sign up</li>
          </div>
          <div>
            <button className="btn btn-login">Log in</button>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default Header;
