import react from "react";
import {Link} from "react-router-dom";

import { FaHome, FaSearch, FaGlobe, FaPlus } from "react-icons/fa";

const Nav = () => {

    return (
      <div className="navBar">
        <div className="navl">
          <ul>
             <Link to="/"><li className="active">
                <FaHome />
                <span> Home</span>
              </li></Link>
              

              
              <Link to="/search"><li>
                <FaSearch />
                <span>Search</span>
              </li></Link>  
          </ul>
        </div>

        <div className="library">
          <div className="header-lab">
            <div className="lab-collapse">
              <li>Your Library</li>
            </div>

            <div className="icon">
              <li>
                <FaPlus />
              </li>
            </div>
          </div>

          <div className="playlist-scroll" >
            <div className="playlist">
              <h4>Create play list</h4>
              <div className="playlist-button">
                <p>It's easy, we'll help you</p>
                <button className="buttonStyle">create playlist</button>
              </div>
            </div>

            <div className="playlist">
              <h4>Lets find some podcast to follow</h4>
              <div className="playlist-button">
                <p>We'll keep you updated on new episodes</p>
                <button className="buttonStyle">Browse podcasts</button>
              </div>
            </div>
          </div>

          <div className="cookies-button">
            <div className="cookies">
              <div className="policies">
                <span className="policy">Legal</span>
                <span className="policy">Privacy Center</span>
                <span className="policy"> Privacy Policy</span>
              </div>

              <div className="abouts">
                <span className="about">Cookies</span>
                <span className="about">About Ads</span>
                <span className="about">Accessibility</span>
              </div>

              <span>Cookies</span>
            </div>

            <div className="button">
              <button className="buttonStyle">
                <FaGlobe /> English
              </button>
            </div>
          </div>
        </div>
      </div>
    );

} 

export default Nav;