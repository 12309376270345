import react, { useState, useRef, useEffect } from "react";
import MyImage from "../assets/images/ttheweeknd.jpeg";
import PlayIcon from "../assets/images/playIcon.png";
import Playlists from "./Playlist";

const Categories = () => {
    const [limiter, setLimiter] = useState(0);
    const mainInnerRef = useRef();
    const dataCategories =[
        {
            id:1,
            name:" Spotify Playlist" 
        },

        {
            id:2,
            name:" Focus" 
        },
        
    ]

    useEffect(() =>{
        //function
        const handleWindowResize = () =>{
            //calculations
            const calculation = Math.floor(mainInnerRef.current.getBoundingClientRect().width /180);
            setLimiter(calculation);
            //console.log( )
        }

        handleWindowResize();

        //assign event listener
        window.addEventListener("resize", handleWindowResize);

        //remove event listner
        return() => window.removeEventListener("resize", handleWindowResize)
    }, [mainInnerRef]);
    

  return (
    <div>
        <div className="mainInner" ref={mainInnerRef}>
            {dataCategories.map((category, id) =>(
                <div className="cardsWraps" key={id} >
                    <h1> {category.name}</h1>
                    <Playlists category_id={category.id} limiter={limiter}/>
                    {/* <span className="seeall">SEE ALL </span>*/}
                    
                </div>
            ))} 
        </div>
    </div>    
  );
};

export default Categories;
