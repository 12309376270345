import react, { useState } from "react";

import MyImage from "../assets/images/ttheweeknd.jpeg";
import MyPlay from "../assets/images/images.jpeg";
import MyPlay1 from "../assets/images/download.jpeg";
import MyPlay2 from "../assets/images/rock.jpeg";
import MyPlay3 from "../assets/images/chills.jpeg";
import focus from "../assets/images/piano.jpeg";
import focus1 from "../assets/images/deep.jpeg";
import focus2 from "../assets/images/study.jpeg";
import focus3 from "../assets/images/flow.jpeg";
import focus4 from "../assets/images/launch.jpeg";

import PlayIcon from "../assets/images/playIcon.png";
//import {Link} from "react-router-dom";

const Playlists = (props) =>{
    const [isHovered, setIsHovered] = useState(false);
    const dataPlayLists = [
      {
        id: 101,
        category_id: 1,
        name: "Today's Top Hits",
        img: MyImage,
        desc: "The Weeknd and Ariana Grande is on top of the Hottest 50!",
      },
      {
        id: 102,
        category_id: 1,
        name: "RapCaviar",
        img: MyPlay,
        desc: "New music from Doja Cat, Rod Wave and Lil Wayne.",
      },
      {
        id: 104,
        category_id: 1,
        name: "All out 2010s",
        img: MyPlay1,
        desc: "the biggest songs of the 2010s.",
      },
      {
        id: 105,
        category_id: 1,
        name: "Rock Classic",
        img: MyPlay2,
        desc: "Rock Legends & epic songs that continue to inspire...",
      },
      {
        id: 106,
        category_id: 1,
        name: "ChillHits",
        img: MyPlay3,
        desc: "kick back to the best new and recent chill",
      },
      {
        id: 106,
        category_id: 1,
        name: "Home Playlist",
        //img:""
      },

      //focus category
      {
        id: 107,
        category_id: 2,
        name: "Peaceful Piano",
        img: focus,
        desc: "Peaceful piano to help you slow down......",
      },
      {
        id: 108,
        category_id: 2,
        name: "Deep Focus",
        img: focus1,
        desc: "Keep calm and focus with ambient and post-rock music......",
      },
      {
        id: 109,
        category_id: 2,
        name: "Instrumental Study",
        img: focus2,
        desc: "Focus with soft study music in the background...",
      },
      {
        id: 110,
        category_id: 2,
        name: "Focus FLow",
        img: focus3,
        desc: "Uptempo instrumental hip hop beats.",
      },
      {
        id: 111,
        category_id: 2,
        name: "workday launch",
        img: focus4,
        desc: "Launch a and chill out music for....",
      },
      {
        id: 112,
        category_id: 2,
        name: "Beats to think to",
        img: focus4,
        desc: "Focus with melodic techno and tech house.",
      },
    ];

    const matchedPlaylists = dataPlayLists.filter(Playlist => Playlist.category_id === props.category_id).slice(0, props.limiter)
    return(
        <div className="cardsWrap">

            {matchedPlaylists.map((playlist, id)=>(
                <div
                    className={`card ${isHovered ? "hovered" : ""}`}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)} key={id}
                >
                    <div className="cardImage">
                        {/*<img src={MyImage} alt="pic 1" />*/}
                         <img src={playlist.img} alt="" />
                    </div>
                    <div className="cardContent">
                        <h3> {playlist.name} </h3>
                        <p>{playlist.desc}</p>
                    </div>
                    <span className="playIcon">
                        <img src={PlayIcon} alt="" />
                    </span>
                </div>
            ))}
                    
        </div>
    )
}

export default Playlists;