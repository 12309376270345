import React from "react";
import { Button } from "react-native-web";

const MusicControls = () => {
  return (
    <div className='controls'>
          <div className="prewiew">
            <h1> PREVIEW OF SPOTIFY</h1>
            <span className="">
              Sign up to get unlimited songs and podcasts with occosional ads. No
              credit card needed
            </span>
          </div>

          <div className="signup-btn">
            <button>sign up free</button>
          </div>
        </div>
  );
};

export default MusicControls;
