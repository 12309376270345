import React from 'react';
import "font-awesome/css/font-awesome.min.css";

const Footer = () => {

    return (
      <div className="theFooter">
        <div className="links">
          <div className="company">
            <h1 className="company">Company</h1>
            <ul className="">
              <li className="">About</li>
              <li className="">Jobs</li>
              <li className="">For the Record</li>
            </ul>
          </div>
          <div className="company">
            <h1 className="company">Communities</h1>
            <ul className="">
              <li className="">For Artists</li>
              <li className="">Developers</li>
              <li className="">Advertising</li>
              <li className="">vendors</li>
            </ul>
          </div>
          <div className="company">
            <h1 className="company">Useful links</h1>
            <ul className="">
              <li className="">Support</li>
              <li className="">Free Mobile App</li>
            </ul>
          </div>

          <div className="socialMedia">
            
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-button"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-button"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-button"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </div>
        </div>

        <div className="copyright">
          
          <span>&#169; 2023 Spotify AB</span>
        </div>
      </div>
    );
}

export default Footer;