import react, { useState, useRef, useEffect } from "react";
import Playlists from "./Playlist";

const SearchCat = () => {
  const [limiter, setLimiter] = useState(0);
  const mainInnerRef = useRef();
  const dataCategories = [
    {
      id: 1,
      name: " Spotify Playlist",
    },

    {
      id: 2,
      name: " Focus",
    },
  ];

  return (
    <div>
      <div className="">Search page</div>
      <div className="mainInner" ref={mainInnerRef}></div>
    </div>
  );
};

export default SearchCat;
